import axios from "@/plugins/axios"
import qs from "qs"
export const getSearchResults = async ({ term, pagination }, cityId) => {
  let queryPopulate = {}
  let field = "name"
  if (!cityId) {
    cityId =
      localStorage.key("selectCity") && localStorage.getItem("selectCity") !== "undefined"
        ? JSON.parse(localStorage.getItem("selectCity"))?.id
        : 2
  }
  field = "name"
  queryPopulate = {
    populate: {
      image: "*",
      localizations: "*",
    },
    filters: {
      $and: [
        {
          [field]: {
            $containsi: term,
          },
        },
      ],
    },
  }

  const query = qs.stringify(
    {
      pagination: {
        page: pagination?.page || 1,
        pageSize: pagination?.pageSize || 1500,
      },
      ...queryPopulate,
    },
    {
      encodeValuesOnly: true,
    },
  )

  const response = await axios.get(`/products?${query}`)
  return response?.data
}
