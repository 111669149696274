<template>
  <div
    v-if="isSearchingOpen"
    class="mobile__search"
  >
    <div class="container">
      <form
        class="header__search"
        @submit.prevent="searchGo"
      >
        <input
          class="header__search-input"
          type="text"
          :placeholder="$t('find')"
          v-model="searchItem"
          @click="removeClass"
        />
        <div
          v-if="searchItem.length > 0"
          style="display: flex; justify-content: center"
          @click="cleanSearch"
        >
          <img
            src="@/assets/icons/x-circle.svg"
            alt=""
          />
        </div>
        <button
          v-if="searchItem.length > 0"
          class="header__search-btn"
          style="font-size: 14px; color: #000000; font-family: 'CeraPro Medium'"
        >
          найти
        </button>
        <button
          v-else
          class="header__search-btn"
        >
          <img
            class="header__search-img"
            src="@/assets/img/icons/search-icon.svg"
            alt=""
          />
        </button>
      </form>

      <!-- SEARCH_RESULTS -->

      <div @click="goToProduct">
        <template v-if="countProducts && searchItem.length > 0">
          <product-card
            v-for="product in products"
            :key="product.id"
            :item="product"
          />
        </template>
        <div
          v-else
          id="nothing__text"
          class="invisible__text"
          style="color: #999999; margin-top: 18px; font-family: 'CeraPro Medium'"
        >
          По вашему запросу ничего не найдено
        </div>
        <div
          v-if="isLoading"
          class="loading"
        >
          <div class="loader"></div>
        </div>
      </div>

      <!-- CATEGORIES -->
      <div class="header-menu-mobile__accordions">
        <div
          class="header-menu-mobile-accordion"
          v-for="(category, index) in menu"
          :key="index"
        >
          <h2
            class="header-menu-mobile-accordion-title"
            v-if="category.data.length"
            @click="category.isOpen = !category.isOpen"
          >
            {{ category.category.label }}
            <img
              src="@/assets/img/header-menu/down.svg"
              :class="{ rotata: category.isOpen }"
            />
          </h2>
          <div
            class="header-menu-mobile-accordion__next"
            v-if="category.data.length && category.isOpen"
          >
            <div
              v-for="(list, index) in category.data"
              :key="index"
            >
              <h2
                class="header-menu-mobile-accordion__next-title"
                @click="list.isOpen = !list.isOpen"
                v-if="list.data.length"
              >
                {{ $t(list.label) }}
                <img
                  src="@/assets/img/header-menu/down.svg"
                  :class="{ rotata: list.isOpen }"
                />
              </h2>
              <div
                class="header-menu-mobile-accordion__next__last"
                v-if="list.data.length && list.isOpen"
                @click="mutateModal"
              >
                <locale-router-link
                  tag="h2"
                  :to="`catalog?category=${category.category.id}&${getQuery(item)}`"
                  v-for="(item, index) in list.data"
                  :key="index"
                  @click="mutateModal"
                >
                  {{ item.label ? item.label : item }}
                </locale-router-link>
              </div>
            </div>
            <div @click="mutateModal">
              <locale-router-link
                tag="p"
                :to="`catalog?category=${category.category.id}`"
              >
                Все
              </locale-router-link>
            </div>
          </div>
          <div v-else-if="category.data.length === 0 && category.isOpen">
            <h2 class="header-menu-mobile-accordion__isempty">В этой категории пусто</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex"
import { mutationTypes } from "@/store"
import { actionTypes } from "@/store/modules/catalog"
import { getSearchResults } from "@/api/search"

export default {
  data() {
    return {
      searchItem: "",
      menu: [],
      isLoading: false,
      // notInList: true,
      menuAttributes: [
        {
          type: "countries",
          value: "country",
          data: [],
        },
        {
          type: "sugar",
          value: "sugar",
          data: [],
        },
        {
          type: "volume",
          value: "volume",
          data: [],
        },
        {
          type: "strenght",
          value: "strenght",
          data: [],
        },
        {
          type: "vinePlace",
          value: "vine_place",
          data: [],
        },
      ],
    }
  },
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
  },
  computed: {
    ...mapState(["isSearchingOpen"]),
    ...mapState("categoriesModule", ["categoriesFiltered", "categoriesProducts"]),
    ...mapState("catalogModule", {
      products: "data",
      meta: "meta",
      isLoading: "isLoading",
    }),
    countProducts() {
      return this.products?.length
    },
  },
  watch: {
    "$route.path": {
      deep: true,
      handler() {
        if (this.isSearchingOpen) {
          this.closeMenu()
        }
      },
    },
    isSearchingOpen: {
      handler() {
        if (this.isSearchingOpen) {
          this.menu = []
          this.categoriesFiltered.forEach((category) => {
            this.categoriesProducts
              .find((e) => e.id === category.id)
              .data.forEach((products) => {
                this.menuAttributes.forEach((atr) => {
                  atr.data.push(products.attributes[atr.value])
                })
              })

            // every category has his own menu
            this.menu.push({
              category: category,
              isOpen: false,
              data: [
                {
                  type: "view",
                  label: "attributes.view",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "countries",
                  label: "attributes.country",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "sugar",
                  label: "attributes.sugar",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "volume",
                  label: "attributes.volume",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "strenght",
                  label: "attributes.fortress",
                  data: [],
                  isOpen: false,
                },
                {
                  type: "vinePlace",
                  label: "attributes.grape",
                  data: [],
                  isOpen: false,
                },
              ],
            })

            // sort attributes by their types
            this.menuAttributes.forEach((atr) => {
              this.menu
                .find((menu) => {
                  return menu.category.id === category.id
                })
                .data.find((e) => e.type === atr.type).data = Array.from(
                new Set(atr.data.filter((element) => element != null && element != "")),
              )
            })

            // clear menu attributes
            this.menuAttributes.forEach((atr) => {
              atr.data = []
            })

            // special sorting for view typed filter
            category.subcategories.forEach((e) => {
              if (e.products.length) {
                this.menu
                  .find((menu) => menu.category.id === category.id)
                  .data.find((e) => e.type === "view")
                  .data.push({
                    id: e.id,
                    label: e.label,
                  })
              }
            })
          })
        } else {
          this.menuAttributes.forEach((atr) => {
            atr.data = []
          })
        }
      },
    },
  },
  methods: {
    ...mapMutations({
      modalSearching: mutationTypes.openSearchingModal,
    }),
    ...mapActions("catalogModule", {
      actionLoadProductsSuccess: actionTypes.actionLoadProductsSuccess,
    }),
    mutateModal() {
      this.modalSearching(false)
    },
    cleanSearch() {
      this.searchItem = ""
    },
    goToProduct() {
      console.log("test")
      this.modalSearching(false)
    },
    removeClass() {
      var element = document.getElementById("nothing__text")
      element.classList.remove("invisible__text")
    },
    searchGo() {
      if (this.searchItem.length > 0) {
        this.loadProductsBySearch()
      } else {
        this.searchItem = ""
      }
    },
    getQuery(item) {
      if (item?.id) {
        return `&view=${item.id}`
      } else {
        return `&label=${item}`
      }
    },
    loadProductsBySearch() {
      this.isLoading = true
      getSearchResults(
        {
          term: this.searchItem,
          pagination: this.pagination,
        },
        this.$route.query.city_id,
      )
        .then((res) => {
          // res.data.forEach((element) => {
          //   let boolExp = element.attributes.name.includes(this.searchItem)
          //   if (boolExp == false) {
          //     this.notInList = true
          //   } else {
          //     this.notInList = false
          //   }
          // })
          this.actionLoadProductsSuccess({
            res: res,
            isPaginate: this.isPaginate,
          })
          this.isLoading = false
        })
        .catch(() => {
          this.actionLoadProductsSuccess()
          this.isLoading = false
        })
    },
  },
}
</script>

<style
  lang="scss"
  scoped
>
.mobile__search {
  position: fixed;
  top: 75px;
  width: 100%;
  right: 0;
  height: 100%;
  background-color: #fefefe;
  z-index: 1009999999999;
  transition: 0.3s all;
  overflow: visible;
  overflow-y: auto;
}
.header__search {
  margin-top: 14px;
  width: 100%;
  border: #8344d6 1px solid;
  input {
    color: #2d2f32;
  }
}
.loading {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.invisible__text {
  display: none;
}
</style>
